<template>
  <div>
    <div v-loading="true"></div>
  </div>
</template>

<script>
import { oidcSettings } from '../../auth/config';

export default {
  methods: {
    tryLogin() {
      const settings = oidcSettings();

      let params = `?client_id=${settings.client_id}`;
      params += `&redirect_uri=${settings.redirect_uri}`;
      params += `&response_type=${settings.response_type}`;
      params += `&scope=${settings.scope}`;
      params += `&nonce=${this.generateNonce(32)}`;
      const studentLoginUrl = `${
        settings.authority
      }/Feide/Login?returnUrl=/connect/authorize/callback${encodeURIComponent(
        params
      )}`;

      setTimeout(() => {
        window.location.href = studentLoginUrl;
      }, 1000);
    },

    generateNonce(len) {
      const hex = '0123456789ABCDEF';
      let output = '';
      for (let i = 0; i < len; ++i) {
        output += hex.charAt(Math.floor(Math.random() * hex.length));
      }
      return output;
    }
  },

  created() {
    this.tryLogin();
  }
};
</script>
